import React, { useState } from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa'; // Import icons
import img1 from '../src/Assetes/img1.jpg'; // Replace with the correct path to your image
import img2 from '../src/Assetes/img2.jpg'; // Replace with the correct path to your image
import img3 from '../src/Assetes/img3.jpg'; // Replace with the correct path to your image

const Home = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the email submission here
        alert(`Subscribed with: ${email}`);
    };

    return (
        <div className="min-h-screen flex flex-col relative">
            {/* Top Banner */}
            <div className="bg-yellow-100 text-center py-2">
                Are you ready for our new Website?
            </div>

            {/* Main Content */}
            <div className="flex flex-col md:flex-row flex-1">
                {/* Left Image Section */}
                <div className="flex-1 relative h-64 md:h-auto">
                    <img
                        src={img1} // The imported image is now used here
                        alt="Coming Soon"
                        className="object-cover h-full w-full"
                    />

                    {/* FrontLine Text - Top Left */}
                    <div className="Organum absolute top-4 left-4 text-[40px] md:text-[40px] text-white text-2xl font-bold">
                        FrontLine
                    </div>

                    {/* Social Media Icons on Mobile (below image) */}
                    <div className="flex justify-center space-x-6 mt-4 md:hidden mb-5">
                        <a href="#" className="text-gray-700 hover:text-black">
                            <FaFacebookF size={24} />
                        </a>
                        <a href="#" className="text-gray-700 hover:text-black">
                            <FaInstagram size={24} />
                        </a>
                    </div>
                </div>

                {/* Right Content Section */}
                <div className="flex-1 bg-gray-200 flex flex-col justify-center items-center p-8">
                    <h1 className="plaufair text-[40px] text-slate-950 md:text-[72px] mb-8">
                        Coming Soon
                    </h1>

                    {/* Two Images with Mobile Adjustment */}
                    <div className="grid grid-cols-2 gap-5 mb-8 mt-20">
                        <img
                            src={img2} // The imported image is now used here
                            alt="Sweater 1"
                            className="object-cover transform -translate-y-6" // Moves the image upwards on all screens
                        />
                        <img
                            src={img3} // The imported image is now used here
                            alt="Sweater 2"
                            className="object-cover transform translate-y-6" // Moves the image downwards on all screens
                        />
                    </div>

                    {/* Subscribe Form */}
                    <div className="text-center mt-20">
                        <form className="flex flex-col items-center space-y-4">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email *"
                                required
                                className="border-b w-80 border-gray-400 focus:outline-none focus:border-blue-500 py-2 px-4 w-64"
                            />
                            <button
                                type="submit"
                                className="bg-blue-600 text-white py-2 px-6 rounded-lg hover:bg-blue-500 transition-colors"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                    <div className="last mt-14">
                        <p className="figtree">
                            © 2024 by FrontLine. Powered and secured by{' '}
                            <a href="#" className="underline text-blue-500">
                                FrontLine
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            {/* Social Media Icons for Desktop */}
            <div className="absolute bottom-4 left-4 hidden md:block">
                <ul className="space-y-4">
                    <li>
                        <a href="#" className="text-gray-700 hover:text-black">
                            <FaFacebookF size={24} />
                        </a>
                    </li>
                    <li>
                        <a href="#" className="text-gray-700 mb-4 hover:text-black">
                            <FaInstagram size={24} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Home;
